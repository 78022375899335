// import Spinner from './UI/Spinner';

// Generate a global loader component connected to recoil state
export const GlobalLoader = () => {
  return false;
  // return (
  //   <div className="global-loader">
  //     <Spinner
  //       show={false}
  //       className="spinner-container center full-width"
  //     >
  //       <p>Loading</p>
  //     </Spinner>
  //   </div>
  // );
};
